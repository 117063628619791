import { ShopwareSearchParams, ShopwareAssociation } from '@shopware-pwa/types';

export const navigationPageParams: ShopwareSearchParams = {
  associations: {
    cmsPage: {
      associations: {
        sections: {
          associations: {
            blocks: {
              associations: {
                slots: {
                  associations: {
                    block: {
                      associations: {
                        slots: {
                          // eslint-disable-next-line object-curly-newline
                          associations: {},
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    // manufacturer: {
    //   associations: { // eslint-disable-next-line object-curly-newline
    //     media: {}
    //   }
    // },
    media: {},
    // productReviews: {},
    properties: {
      associations: {
        // eslint-disable-next-line object-curly-newline
        group: {},
      },
    },
  },
  includes: {
    cms_page_slot: [
      'id',
      'type',
      'slot',
      'blockId',
      'config',
      'data',
      'backgroundMediaMode',
      'backgroundMedia',
    ],
    cms_page_block: [
      'slots',
      'type',
      'id',
      'backgroundColor',
      'backgroundMedia',
      'sectionPosition',
    ],
    cms_page_section: ['id', 'backgroundMedia', 'blocks', 'type', 'sizingMode'],
    cms_page: ['id', 'name', 'sections', 'type', 'config'],
    product: [
      'media',
      // 'productReviews',
      'name',
      'description',
      'ratingAverage',
      'calculatedPrice',
      'calculatedPrices',
      'calculatedListingPrice',
      'cover',
      'parentId',
      'id',
      'translated',
      'optionIds',
      'properties',
      'manufacturer',
      'seoUrls',
      'crossSellings',
      'availableStock',
      'customFields',
      'stock',
      'metaTitle',
      'metaDescription',
      'metaKeywords',
      'isNew',
      'cheapestPriceContainer',
      'manufacturerNumber',
      // 'productHighlight'
    ],
    product_media: ['media'],
    media: ['thumbnails', 'width', 'height', 'url', 'alt', 'title'],
    media_thumbnail: ['url', 'width', 'height', 'id'],
    calculated_price: ['unitPrice', 'quantity', 'listPrice'],
    product_group_option: ['id', 'group', 'translated', 'name'],
    product_group: ['id', 'translated', 'name'],
    product_listing: [
      'sorting',
      'currentFilters',
      'elements',
      'page',
      'limit',
      'sortings',
      'availableSortings',
      'total',
      'aggregations',
    ],
    property_group: [
      'id',
      'translated',
      'options',
      'name',
      'displayType',
      'customFields',
      'visibleOnProductDetailPage',
    ],
    property_group_option: [
      'translated',
      'name',
      'id',
      'colorHexCode',
      'media',
      'group',
      'customFields',
      'position',
      'product',
      'productsCount',
    ],
    product_manufacturer: ['translated', 'link', 'name', 'customFields'],
  },
};

export const detailPageParams: ShopwareSearchParams = {
  associations: {
    manufacturer: {},
    properties: {
      associations: {
        // eslint-disable-next-line object-curly-newline
        group: {},
      },
    },
    translations: {},
  },
  includes: {
    product: [
      'availableStock',
      'calculatedCheapestPrice',
      'calculatedMaxPurchase',
      'calculatedPrice',
      'calculatedPrices',
      'cheapestPriceContainer',
      'cover',
      'description',
      'ean',
      'id',
      'isCloseout',
      'isNew',
      'manufacturer',
      'markAsTopseller',
      'media',
      'minPurchase',
      'name',
      'options',
      'productNumber',
      'productReviews',
      'properties',
      'purchaseSteps',
      'ratingAverage',
      'sales',
      'seoUrls',
      'shippingFree',
      'tax',
      'translated',
      'translations',
      'customFields',
      'parentId',
      'seoCategory',
      'optionIds',
      'productHighlight',
      'cmsPage',
    ],
    product_media: ['media'],
    media: ['thumbnails', 'width', 'height', 'url', 'id', 'alt', 'title'],
    calculated_price: ['unitPrice', 'quantity', 'listPrice'],
    product_group_option: ['name', 'id', 'group', 'translated'],
    product_group: ['id', 'name', 'options', 'translated', 'position'],
    property_group: [
      'id',
      'translated',
      'options',
      'name',
      'customFields',
      'displayType',
      'visibleOnProductDetailPage',
      'position',
    ],
    property_group_option: [
      'translated',
      'name',
      'id',
      'colorHexCode',
      'media',
      'group',
      'customFields',
      'position',
      'cover',
      'availability',
    ],
    options: ['id', 'translated'],
    cover: ['media'],
  },
};

export const orderPageParams: ShopwareSearchParams = {
  associations: {
    deliveries: {
      associations: {
        shippingMethod: {
          associations: {
            // eslint-disable-next-line object-curly-newline
            media: {},
          },
        },
        shippingOrderAddress: {
          associations: {
            // eslint-disable-next-line object-curly-newline
            country: {},
          },
        },
      },
    },
    addresses: {
      associations: {
        // eslint-disable-next-line object-curly-newline
        country: {},
      },
    },
    billingAddress: {
      associations: {
        // eslint-disable-next-line object-curly-newline
        country: {},
      },
    },
    transactions: {
      associations: {
        paymentMethod: {
          associations: {
            // eslint-disable-next-line object-curly-newline
            media: {},
          },
        },
      },
    },
    lineItems: {
      associations: {
        // eslint-disable-next-line object-curly-newline
        cover: {},
      },
    },
  },
};

export const orderSuccessAssociations: ShopwareAssociation = {
  deliveries: {
    associations: {
      shippingMethod: {
        associations: {
          // eslint-disable-next-line object-curly-newline
          media: {},
        },
      },
      shippingOrderAddress: {
        associations: {
          // eslint-disable-next-line object-curly-newline
          country: {},
        },
      },
    },
  },
  addresses: {
    associations: {
      // eslint-disable-next-line object-curly-newline
      country: {},
    },
  },
  transactions: {
    associations: {
      paymentMethod: {
        associations: {
          // eslint-disable-next-line object-curly-newline
          media: {},
        },
      },
    },
  },
};

export const productVariantParams: ShopwareSearchParams = {
  associations: {
    // eslint-disable-next-line object-curly-newline
    seoUrls: {},
  },
  includes: {
    product: ['id', 'translated', 'productNumber', 'seoUrls', 'availableStock'],
    seo_url: ['seoPathInfo'],
  },
  limit: 1,
};
